<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <!-- end row -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-5"></h4>
            <div class>
              <ul class="verti-timeline list-unstyled">
                <li class="event-list" v-if="this.form.brcusDate != null">
                  <div class="event-date text-primar">
                    {{ getNow(this.form.brcusDate) }} <br />
                  </div>
                  <h5>{{ this.brcus }}</h5>
                  <p class="text-muted mb-0">
                    ผู้รับ : {{ this.form.brcusBy }}
                  </p>
                  <p class="text-muted">
                    เอกสารค้าง : {{ this.form.brcusDiff }} วัน
                  </p>
                </li>

                <li class="event-list" v-if="this.form.sendRegDeptDate != null">
                  <div class="event-date text-primar">
                    {{ getNow(this.form.sendRegDeptDate) }} <br />
                  </div>
                  <h5>{{ this.sendRegDept }}</h5>
                  <p class="text-muted mb-0">
                    ผู้ส่ง : {{ this.form.sendRegDeptBy }}
                  </p>
                  <p class="text-muted">
                    เอกสารค้าง : {{ this.form.sendRegDeptDiff }} วัน
                  </p>
                </li>
                <li class="event-list" v-if="this.form.rcvdbranchDate != null">
                  <div class="event-date text-primar">
                    {{ getNow(this.form.rcvdbranchDate) }} <br />
                  </div>
                  <h5>{{ this.rcvdbranch }}</h5>
                  <p class="text-muted mb-0">
                    ผู้รับ : {{ this.form.rcvdbranchBy }}
                  </p>
                  <p class="text-muted">
                    เอกสารค้าง : {{ this.form.rcvdbranchDiff }} วัน
                  </p>
                </li>

                <li class="event-list" v-if="this.form.sendMsgDate != null">
                  <div class="event-date text-primar">
                    {{ getNow(this.form.sendMsgDate) }} <br />
                  </div>
                  <h5>{{ this.sendMsg }}</h5>
                  <p class="text-muted mb-0">
                    ผู้ส่ง : {{ this.form.sendMsgBy }}
                  </p>
                  <p class="text-muted">
                    เอกสารค้าง : {{ this.form.sendMsgDiff }} วัน
                  </p>
                </li>
                <li class="event-list" v-if="this.form.rcvdMsgDate != null">
                  <div class="event-date text-primar">
                    {{ getNow(this.form.rcvdMsgDate) }} <br />
                  </div>
                  <h5>{{ this.rcvdMsg }}</h5>
                  <p class="text-muted mb-0">
                    ผู้รับ : {{ this.form.rcvdMsgBy }}
                  </p>
                  <p class="text-muted">
                    เอกสารค้าง : {{ this.form.rcvdMsgDiff }} วัน
                  </p>
                </li>
                <li class="event-list" v-if="this.form.sendBranchDate != null">
                  <div class="event-date text-primar">
                    {{ getNow(this.form.sendBranchDate) }} <br />
                  </div>
                  <h5>{{ this.sendBranch }}</h5>
                  <p class="text-muted mb-0">
                    ผู้ส่ง : {{ this.form.sendBranchBy }}
                  </p>
                  <p class="text-muted">
                    เอกสารค้าง : {{ this.form.sendBranchDiff }} วัน
                  </p>
                </li>
                <li class="event-list" v-if="this.form.rcvdRegDeptDate != null">
                  <div class="event-date text-primar">
                    {{ getNow(this.form.rcvdRegDeptDate) }} <br />
                  </div>
                  <h5>{{ this.rcvdRegDept }}</h5>
                  <p class="text-muted mb-0">
                    ผู้รับ : {{ this.form.rcvdRegDeptBy }}
                  </p>
                  <p class="text-muted">
                    เอกสารค้าง : {{ this.form.rcvdRegDeptDiff }} วัน
                  </p>
                </li>
                <li class="event-list" v-if="this.form.sendCusDate != null">
                  <div class="event-date text-primar">
                    {{ getNow(this.form.sendCusDate) }} <br />
                  </div>
                  <h5>{{ this.sendCus }}</h5>
                  <p class="text-muted mb-0">
                    ผู้ส่ง : {{ this.form.sendCusBy }}
                  </p>
                  <!-- <p class="text-muted">
                    เอกสารค้าง : {{ this.form.sendCusDiff }} วัน
                  </p> -->
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">รายละเอียดการส่งเอกสาร</h4>

            <div class="hori-timeline">
              <carousel
                ref="carouselSend"
                class="events navs-carousel"
                id="timeline-carousel"
                :navigation-enabled="false"
                :pagination-enabled="false"
                :perPageCustom="[
                  [480, 2],
                  [768, 4],
                ]"
              >
                <slide
                  v-if="
                    this.form.brcusDate != null ||
                    this.form.sendRegDeptDate != null ||
                    this.form.rcvdbranchDate != null ||
                    this.form.sendMsgDate != null
                  "
                >
                  <div
                    class="item event-list"
                    v-for="(docItem, index) in document"
                    :key="index"
                  >
                    <div class="event" v-if="docItem.docType == 'S'"></div>

                    <div class="px-3" v-if="docItem.docType == 'S'">
                      <p class="text-left">
                        <span> {{ docItem.docTypeNameTh }} </span>
                      </p>
                    </div>
                  </div>
                </slide>
                <slide v-if="this.form.brcusDate != null">
                  <div class="item event-list">
                    <div class="event-date">
                      <div class="text-primary">{{ this.brcus }}</div>
                    </div>

                    <div
                      class="px-3"
                      v-for="(docItem, index) in document"
                      :key="index"
                    >
                      <p
                        class="text-muted text-left"
                        v-if="docItem.docType == 'S'"
                      >
                        <i class="mdi mdi-calendar font-size-18"></i>
                        {{ getNow(docItem.brcusDate) }} <br />
                        <i class="mdi mdi-account font-size-18"></i>
                        {{ docItem.brcusBy }} <br />
                        เอกสารค้าง : {{ docItem.brcusDiff }} วัน
                      </p>
                    </div>
                  </div>
                </slide>
                <slide v-if="this.form.sendRegDeptDate != null">
                  <div class="item event-list">
                    <div class="event-date">
                      <div class="text-primary">{{ this.sendRegDept }}</div>
                    </div>

                    <div
                      class="px-3"
                      v-for="(docItem, index) in document"
                      :key="index"
                    >
                      <p
                        class="text-muted text-left"
                        v-if="docItem.docType == 'S'"
                      >
                        <i class="mdi mdi-calendar font-size-18"></i>
                        {{ getNow(docItem.sendRegDeptDate) }} <br />
                        <i class="mdi mdi-account font-size-18"></i>
                        {{ docItem.sendRegDeptBy }} <br />
                        เอกสารค้าง : {{ docItem.sendRegDeptDiff }} วัน
                      </p>
                    </div>
                  </div>
                </slide>
                <slide v-if="this.form.rcvdbranchDate != null">
                  <div class="item event-list">
                    <div class="event-date">
                      <div class="text-primary">
                        {{ this.rcvdbranch }}
                      </div>
                    </div>

                    <div
                      class="px-3"
                      v-for="(docItem, index) in document"
                      :key="index"
                    >
                      <p
                        class="text-muted text-left"
                        v-if="docItem.docType == 'S'"
                      >
                        <i class="mdi mdi-calendar font-size-18"></i>
                        {{ getNow(docItem.rcvdbranchDate) }} <br />
                        <i class="mdi mdi-account font-size-18"></i>
                        {{ docItem.rcvdbranchBy }} <br />
                        เอกสารค้าง : {{ docItem.rcvdbranchDiff }} วัน
                      </p>
                    </div>
                  </div>
                </slide>
                <slide v-if="this.form.sendMsgDate != null">
                  <div class="item event-list">
                    <div class="event-date">
                      <div class="text-primary">{{ this.sendMsg }}</div>
                    </div>

                    <div
                      class="px-3"
                      v-for="(docItem, index) in document"
                      :key="index"
                    >
                      <p
                        class="text-muted text-left"
                        v-if="docItem.docType == 'S'"
                      >
                        <i class="mdi mdi-calendar font-size-18"></i>
                        {{ getNow(docItem.sendMsgDate) }} <br />
                        <i class="mdi mdi-account font-size-18"></i
                        >{{ docItem.sendMsgBy }} <br />
                        เอกสารค้าง : {{ docItem.sendMsgDiff }} วัน
                      </p>
                    </div>
                  </div>
                </slide>
              </carousel>
              <div
                class="owl-nav"
                v-if="
                  this.form.brcusDate != null ||
                  this.form.sendRegDeptDate != null ||
                  this.form.rcvdbranchDate != null ||
                  this.form.sendMsgDate != null
                "
              >
                <button
                  type="button"
                  role="presentation"
                  class="owl-prev"
                  @click.prevent="prevSlideSend"
                >
                  <i class="mdi mdi-chevron-left"></i>
                </button>
                <button
                  type="button"
                  role="presentation"
                  class="owl-next"
                  @click.prevent="nextSlideSend"
                >
                  <i class="mdi mdi-chevron-right"></i>
                </button>
              </div>
              <div class="row">
                <span
                  v-if="
                    this.form.brcusDate == null &&
                    this.form.sendRegDeptDate == null &&
                    this.form.rcvdbranchDate == null &&
                    this.form.sendMsgDate == null
                  "
                  class="text-center font-size-16"
                  >ไม่พบรายละเอียด</span
                >
              </div>
            </div>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">รายละเอียดการรับเอกสาร</h4>

            <div class="hori-timeline">
              <carousel
                ref="carousel"
                class="events navs-carousel"
                id="timeline-carousel"
                :navigation-enabled="false"
                :pagination-enabled="false"
                :perPageCustom="[
                  [480, 2],
                  [768, 4],
                ]"
              >
                <slide
                  v-if="
                    this.form.rcvdMsgDate != null ||
                    this.form.sendBranchDate != null ||
                    this.form.rcvdRegDeptDate != null ||
                    this.form.sendCusDate != null
                  "
                >
                  <div
                    class="item event-list"
                    v-for="(docItem, index) in document"
                    :key="index"
                  >
                    <div class="event" v-if="docItem.docType == 'R'"></div>

                    <div class="px-3" v-if="docItem.docType == 'R'">
                      <p class="text-left">
                        <span> {{ docItem.docTypeNameTh }} </span>
                      </p>
                    </div>
                  </div>
                </slide>
                <slide v-if="this.form.rcvdMsgDate != null">
                  <div class="item event-list">
                    <div class="event-date">
                      <div class="text-primary">{{ this.rcvdMsg }}</div>
                    </div>
                    <div
                      class="px-3"
                      v-for="(docItem, index) in document"
                      :key="index"
                    >
                      <p
                        class="text-muted text-left"
                        v-if="docItem.docType == 'R'"
                      >
                        <i class="mdi mdi-calendar font-size-18"></i>
                        {{ getNow(docItem.rcvdMsgDate) }} <br />
                        <i class="mdi mdi-account font-size-18"></i>
                        {{ docItem.rcvdMsgBy }} <br />
                        เอกสารค้าง : {{ docItem.rcvdMsgDiff }} วัน
                      </p>
                    </div>
                  </div>
                </slide>
                <slide v-if="this.form.sendBranchDate != null">
                  <div class="item event-list">
                    <div class="event-date">
                      <div class="text-primary">
                        {{ this.sendBranch }}
                      </div>
                    </div>

                    <div
                      class="px-3"
                      v-for="(docItem, index) in document"
                      :key="index"
                    >
                      <p
                        class="text-muted text-left"
                        v-if="docItem.docType == 'R'"
                      >
                        <i class="mdi mdi-calendar font-size-18"></i>
                        {{ getNow(docItem.sendBranchDate) }} <br />
                        <i class="mdi mdi-account font-size-18"></i>
                        {{ docItem.sendBranchBy }} <br />
                        เอกสารค้าง : {{ docItem.sendBranchDiff }} วัน
                      </p>
                    </div>
                  </div>
                </slide>

                <slide v-if="this.form.rcvdRegDeptDate != null">
                  <div class="item event-list">
                    <div class="event-date">
                      <div class="text-primary">
                        {{ this.rcvdRegDept }}
                      </div>
                    </div>

                    <div
                      class="px-3"
                      v-for="(docItem, index) in document"
                      :key="index"
                    >
                      <p
                        class="text-muted text-left"
                        v-if="docItem.docType == 'R'"
                      >
                        <i class="mdi mdi-calendar font-size-18"></i>
                        {{ getNow(docItem.rcvdRegDeptDate) }} <br />
                        <i class="mdi mdi-account font-size-18"></i>
                        {{ docItem.rcvdRegDeptBy }} <br />
                        เอกสารค้าง : {{ docItem.rcvdRegDeptDiff }} วัน
                      </p>
                    </div>
                  </div>
                </slide>
                <slide v-if="this.form.sendCusDate != null">
                  <div class="item event-list">
                    <div class="event-date">
                      <div class="text-primary">
                        {{ this.sendCus }}
                      </div>
                    </div>

                    <div
                      class="px-3"
                      v-for="(docItem, index) in document"
                      :key="index"
                    >
                      <p
                        class="text-muted text-left"
                        v-if="docItem.docType == 'R'"
                      >
                        <i class="mdi mdi-calendar font-size-18"></i>
                        {{ getNow(docItem.sendCusDate) }} <br />
                        <i class="mdi mdi-account font-size-18"></i>
                        {{ docItem.sendCusBy }}
                      </p>
                    </div>
                  </div>
                </slide>
              </carousel>
              <div
                class="owl-nav"
                v-if="
                  this.form.rcvdMsgDate != null ||
                  this.form.sendBranchDate != null ||
                  this.form.rcvdRegDeptDate != null ||
                  this.form.sendCusDate != null
                "
              >
                <button
                  type="button"
                  role="presentation"
                  class="owl-prev"
                  @click.prevent="prevSlide"
                >
                  <i class="mdi mdi-chevron-left"></i>
                </button>
                <button
                  type="button"
                  role="presentation"
                  class="owl-next"
                  @click.prevent="nextSlide"
                >
                  <i class="mdi mdi-chevron-right"></i>
                </button>
              </div>
              <div class="row">
                <span
                  v-if="
                    this.form.rcvdMsgDate == null &&
                    this.form.sendBranchDate == null &&
                    this.form.rcvdRegDeptDate == null &&
                    this.form.sendCusDate == null
                  "
                  class="text-center font-size-16"
                  >ไม่พบรายละเอียด</span
                >
              </div>
            </div>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </Layout>
</template>
<script>
import {
  required,
  //   maxValue,
  //   minValue,
  //   maxLength,
  //   numeric,
} from "vuelidate/lib/validators";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import Swal from "sweetalert2";
import useNetw from "@useNetw";
import { Carousel, Slide } from "vue-carousel";
/**
 * Form validation component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Carousel,
    Slide,
  },
  page: {
    title: appConfig.regCheckStatus,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      apiShow: "api/reg-check-status/show",
      apiImg: "api/reg-check-status/full-size-image",
      brcus: "รับเอกสารจากลูกค้า",
      sendRegDept: "ส่งเอกสารให้แผนกทะเบียน",
      rcvdbranch: "รับเอกสารจากสาขา",
      sendMsg: "ส่งเอกสารให้ผู้เดินทะเบียน",
      rcvdMsg: "รับเอกสารจากผู้เดินทะเบียน",
      sendBranch: "ส่งเอกสารคืนสาขา",
      rcvdRegDept: "สาขารับคืนเอกสาร",
      sendCus: "ส่งเอกสารคืนลูกค้า",
      isReceived: "ได้ส่งเอกสาร",
      receivedBy: "ผู้ส่ง",
      overlayFlag: false,
      dismissSecs: 3,
      resalert: "",
      dismissCountDown: 0,
      loading: undefined,
      singleImage: false,
      imagePath: "",
      loadingPer: false,
      message: "โปรดตรวจสอบข้อมูล",
      title: "รายละเอียดการตรวจสอบสถานะ",
      items: [
        {
          text: "งานทะเบียน",
          active: true,
        },
        {
          text: "ตรวจสอบสถานะเอกสาร",
          href: "/reg-check-status",
        },
        {
          text: "รายละเอียดการตรวจสอบสถานะ",
          active: true,
        },
      ],
      diff: "",
      rowData: [],
      optionCus: [],
      localData: [],
      localDataBranchUser: [],
      localDataBranchIdUser: [],
      optionsYear: [],
      filter: {
        startDate: "",
        endDate: "",
        branchId: "",
        licensePlate: "",
        regCode: "",
        rcvdCode: "",
        docTypeId: "",
      },
      filterPer: {
        name: "",
        familyName: "",
      },
      fields: [
        {
          key: "index",
          label: "เลือก",
        },
        {
          key: "regCode",
          sortable: true,
          label: "เลขที่ใบแจ้งจำหน่าย",
        },
        {
          key: "rcvdCode",
          sortable: true,
          label: "เลขที่รับเอกสาร",
        },
        {
          key: "customerNameTh",
          sortable: true,
          label: "ชื่อลูกค้า",
        },
        {
          key: "customerFamilyNameTh",
          sortable: true,
          label: "นามสกุลลูกค้า",
        },
        {
          key: "docTypeNameTh",
          sortable: true,
          label: "ประเภทเอกสาร",
        },
        {
          key: "rcvdDate",
          sortable: true,
          label: "วันที่รับเอกสาร",
        },

        {
          key: "licensePlate",
          sortable: true,
          label: "ทะเบียนรถ",
        },
        {
          key: "status",
          label: "สถานะ",
        },
        {
          key: "branchName",
          sortable: true,
          label: "สาขา",
        },
      ],
      fieldsPerson: [
        {
          key: "index",

          label: "ลำดับ",
        },
        {
          key: "personCode",
          sortable: true,
          label: "เลขที่พนักงาน",
        },
        {
          key: "name",
          sortable: true,
          label: "ชื่อพนักงาน",
        },
        {
          key: "familyName",
          sortable: true,
          label: "นามสกุลพนักงาน",
        },
        {
          key: "mobilePhone",
          sortable: true,
          label: "เบอร์โทร",
        },
        {
          key: "email",
          sortable: true,
          label: "อีเมล",
        },
      ],
      regJobId: this.$route.params.regJobId,

      document: [],
      rowPerson: [],
      rowDocType: [],
      branchIdSearch: "",
      branchIdRo: "",
      rowRo: [],
      page: "",
      totalPage: "",
      perPage: 10,
      currentPage: 1,
      rowpage: "",
      totalRecord: "",
      totalRecordRe: "",
      rowTest: [],
      totalRows: 1,
      totalRecordPer: "",
      totalPagePer: "",
      perPagePer: 10,
      currentPagePer: 1,
      pageOptionsPer: [5, 10, 25, 50, 100],
      pageOptions: [5, 10, 25, 50, 100],
      selectMode: "single",
      selected: [],
      isLoading: false,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      optionsGroupId: [],
      optionsUnits: [],
      options: [],

      optionDoc: [],
      optionInvoice: [],
      isDisabled: false,
      rowsVehi: [],
      rowsData: [],
      optionTypeDoc: [],
      optionSale: [
        { value: "1", text: "รถใหม่" },
        { value: "0", text: "รถต่อทะเบียน" },
      ],
      form: {
        brcusDate: "",
        brcusBy: "",
        brcusDiff: "",

        sendRegDeptDate: "",
        sendRegDeptBy: "",
        sendRegDeptDiff: "",

        rcvdbranchDate: "",
        rcvdbranchBy: "",
        rcvdbranchDiff: "",

        sendMsgDate: "",
        sendMsgBy: "",
        sendMsgDiff: "",

        rcvdMsgDate: "",
        rcvdMsgBy: "",
        rcvdMsgDiff: "",

        sendBranchDate: "",
        sendBranchBy: "",
        sendBranchDiff: "",

        rcvdRegDeptDate: "",
        rcvdRegDeptBy: "",
        rcvdRegDeptDiff: "",

        sendCusDate: "",
        sendCusBy: "",
        sendCusDiff: "",

        customerNameTh: "",
        customerFamilyNameTh: "",
        licensePlate: "",
        vin: "",
        modelNameTh: "",
        status: "",
        branchId: "",
      },

      submitform: false,
      submit: false,
    };
  },

  validations: {
    form: {
      personId: {
        required,
      },
      // cause: {
      //   required,
      // },
      // customerDecision: {
      //   required,
      // },
    },
  },
  computed: {},
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
      if (user.branchId == item.branchId) {
        this.branchIdSearch = { nameTh: item.nameTh, branchId: item.branchId };
      }
    });
    this.localDataBranchIdUser = arrayBranch;
    this.branchId = user.branchId;
    this.localData = user.userAccessBranch;

    this.getDataShow();
    // this.getPerson();
    // this.getDocType();
  },
  created() {
    // this.getLocalData();
  },
  methods: {
    nextSlide() {
      this.$refs.carousel.goToPage(this.$refs.carousel.getNextPage());
    },
    prevSlide() {
      this.$refs.carousel.goToPage(this.$refs.carousel.getPreviousPage());
    },
    nextSlideSend() {
      this.$refs.carouselSend.goToPage(this.$refs.carouselSend.getNextPage());
    },
    prevSlideSend() {
      this.$refs.carouselSend.goToPage(
        this.$refs.carouselSend.getPreviousPage()
      );
    },
    getNow: function (newDate) {
      var monthNamesThai = [
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤษจิกายน",
        "ธันวาคม",
      ];
      var monthNamesEng = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      this.monthNamesThai = monthNamesThai;
      this.monthNamesEng = monthNamesEng;
      const today = new Date(newDate);
      const date = today.getDate() + " " + this.monthNamesEng[today.getMonth()];
      return date;
    },
    customLabelPer({ name, familyName, personCode }) {
      return `${name != null ? name : ""}  ${
        familyName != null ? familyName : " "
      }-[${personCode != null ? personCode : ""}  ]`;
    },
    onRowSelected(data) {
      //(data);
      this.selected = data[0];

      var personId = {
        name: this.selected.name,
        familyName: this.selected.familyName,
        personCode: this.selected.personCode,
        personId: this.selected.personId,
      };
      this.form.personId = personId;
      // this.form.branchId = branchName;
      this.$refs["modalPerson"].hide();
    },
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      this.branchId = user.branchId;

      const localDataBranch = [];
      const localDataBranchId = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item), localDataBranchId.push(item.branchId);
      });
      this.localDataBranchUser = localDataBranch;
      this.localDataBranchIdUser = localDataBranchId;
      this.getRo();
    },

    getDataShow: function () {
      this.overlayFlag = true;
      useNetw
        .get(this.apiShow, {
          params: {
            regJobId: atob(this.regJobId),
          },
        })
        .then((response) => {
          this.rowData = response.data.data;
          this.document = response.data.data.document;
          this.form.branchId = {
            nameTh: response.data.data.branchName,
            branchId: response.data.data.branchId,
          };

          this.form.status = response.data.data.status;
          this.form.customerNameTh = response.data.data.customerNameTh;
          this.form.customerFamilyNameTh =
            response.data.data.customerFamilyNameTh;
          this.form.licensePlate = response.data.data.licensePlate;
          this.form.vin = response.data.data.vin;
          this.form.modelNameTh = response.data.data.modelNameTh;

          this.form.brcusDate = response.data.data.brcusDate;
          this.form.brcusBy = response.data.data.brcusBy;
          this.form.brcusDiff = response.data.data.brcusDiff;

          this.form.sendRegDeptDate = response.data.data.sendRegDeptDate;
          this.form.sendRegDeptBy = response.data.data.sendRegDeptBy;
          this.form.sendRegDeptDiff = response.data.data.sendRegDeptDiff;

          this.form.rcvdbranchDate = response.data.data.rcvdbranchDate;
          this.form.rcvdbranchBy = response.data.data.rcvdBranchBy;
          this.form.rcvdbranchDiff = response.data.data.rcvdbranchDiff;

          this.form.sendMsgDate = response.data.data.sendMsgDate;
          this.form.sendMsgBy = response.data.data.sendMsgBy;
          this.form.sendMsgDiff = response.data.data.sendMsgDiff;

          this.form.rcvdMsgDate = response.data.data.rcvdMsgDate;
          this.form.rcvdMsgBy = response.data.data.rcvdMsgBy;
          this.form.rcvdMsgDiff = response.data.data.rcvdMsgDiff;

          this.form.sendBranchDate = response.data.data.sendBranchDate;
          this.form.sendBranchBy = response.data.data.sendBranchBy;
          this.form.sendBranchDiff = response.data.data.sendBranchDiff;

          this.form.rcvdRegDeptDate = response.data.data.rcvdRegDeptDate;
          this.form.rcvdRegDeptBy = response.data.data.rcvdRegDeptBy;
          this.form.rcvdRegDeptDiff = response.data.data.rcvdRegDeptDiff;

          this.form.sendCusDate = response.data.data.sendCusDate;
          this.form.sendCusBy = response.data.data.sendCusBy;
          this.form.sendCusDiff = response.data.data.sendCusDiff;
          this.getNow();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message + "<br>" + err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getDataloadImage: function (data) {
      this.fileId = data.fileId;
      useNetw
        .get(this.apiImg, {
          params: {
            regJobId: atob(this.regJobId),
            fileId: this.fileId,
          },
        })
        .then((response) => {
          //(this.imagePath);
          this.rowImg = response.data.data;
          this.imagePath = response.data.data.filePath;
          this.$refs["modalImg"].show();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          // this.loading = false; //skeleton false
        });
    },
    getRecDoc: function () {
      this.loading = true;
      useNetw
        .get("api/reg-send-msg/dealer-reg-rcvd-branch", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            "branchId[]":
              this.branchIdSearch != null
                ? this.branchIdSearch.branchId
                : this.branchId,
            startDate: this.filter.startDate,
            rcvdCode: this.filter.rcvdCode,
            endDate: this.filter.endDate,
            regCode: this.filter.regCode,
            licensePlate: this.filter.licensePlate,
            docTypeId:
              this.filter.docTypeId != null
                ? this.filter.docTypeId.docTypeId
                : this.filter.docTypeId,
          },
        })
        .then((response) => {
          //(response);
          this.rowsData = response.data.data;
          const per_Page = response.data.perPage;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord / per_Page);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.response.data.message),
              appConfig.swal.type.error
            );
            // this.$router.push("/login");
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    getDocType: function () {
      this.loading = true;
      // this.branchIdRo = branchId;
      useNetw
        .get("api/reg-send-msg/dealer-document-type", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
          },
        })
        .then((response) => {
          this.rowDocType = response.data.data;
          const per_Page = response.data.perPage;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord / per_Page);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.response.data.message),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert(response) {
      this.dismissCountDown = this.dismissSecs;
      this.resalert = response;
    },
    putCheck(docId) {
      useNetw
        .put(this.apiCheck, {
          docId: docId.docId,
        })

        .then((response) => {
          this.getDataShow();
          this.showAlert(response.data.message);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$router.push({ name: "purchase-order" });
          // this.overlayFlag = false;
        });
    },
    alertSubmit() {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการยืนยันหรือไม่ !!",
          icon: "warning",
          confirmButtonText: "OK",
          cancelButtonText: "Cancel!",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.putSubmit();
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "ยกเลิกเรียบร้อยแล้ว",
              "error"
            );
          }
        });
    },
    putSubmit() {
      useNetw
        .put("api/reg-send-msg/submit", {
          regJobId: atob(this.regJobId),
          personId: this.form.personId.personId,
        })

        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );

          this.$router.push({ name: "sendDocReg" });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },
    formDatas() {
      this.submitform = true;
      this.$v.$touch();
      if (this.$v.form.$invalid != true) {
        this.alertSubmit();
      }
    },
    postSubmit() {
      useNetw
        .post("api/reg-send-msg/store", {
          sendDate: this.form.sendDate,
          personId: this.form.personId.personId,
          note: this.form.note,

          docId: this.form.docId,
        })

        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$router.push({ name: "sendDocReg" });
        })

        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },

    handleSearch() {
      if (this.branchIdSearch === "") {
        this.branchId;
      } else {
        this.branchId = this.branchIdSearch.branchId;
      }
      this.getRecDoc();
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.getRecDoc();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getRecDoc();
    },
    handleSearchPer() {
      if (this.branchIdSearch === "") {
        this.branchId;
      } else {
        this.branchId = this.branchIdSearch.branchId;
      }
      this.getPerson();
    },
    handleChangePagePer(page) {
      this.currentPagePer = page;
      this.getPerson();
    },
    handlePageChangePer(active) {
      this.perPagePer = active;
      this.currentPagePer = 1;
      this.getPerson();
    },
  },
  middleware: "authentication",
};
</script>

<style lang="scss" scoped>
.preview-img {
  max-width: 200px;
}
@media only screen and (min-width: 500px) {
  .alert-fixed {
    position: fixed;
    top: 50px;
    left: 65%;
    width: 30%;
    z-index: 9999;
    border-radius: 0px;
  }
}
.text-left {
  text-align: left;
}
.event {
  display: inline-block;
  position: relative;
  border-radius: 4px;
  background-color: #ffffff00;
  padding: 4px 12px;
  margin-bottom: 60px;
}
.padding {
  padding-bottom: 45px;
}
</style>
